import { CheckBox, Close, Info, List, LocalActivity, Pin, PinDrop, Satellite, SensorDoor, Sensors, Task, TaskAlt } from "@mui/icons-material";
import { Box, Button, ButtonGroup, CircularProgress, Fab, IconButton, Paper, Table, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import { useContext, useState } from "react";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import COTooltip from "../ui/COTooltip";
import WidgetDescription from "../widgets/WidgetDescription";
import IndexChart from "./IndexChart";
import TillageModal from "./TillageModal";

const applicabilityMap = {
  'tcd': 'Tree Cover Density',
  'wetlands': 'Wetlands',
  'corine': 'Corine Land Cover',
  'worldcover': 'World Cover',
  'plough': 'Ploughing < 2018',
  'lc_uk': 'Land Cover UK',
}

function VerificationWidget(props) {

  const { layerA } = useContext(DataContext)

  const [tillageModal, setTillageModal] = useState(null);
  const [tillageDeclaredModal, setTillageDeclaredModal] = useState(null);


  const renderDeclaredCrop = (crop) => {
    let na = <span className="text-bold" style={{color: variables.warningColor}}>N/A</span>;
    try {
      if(!crop) return na;

      if(crop === 'NA' || crop === 'N/A') return na;

      return <span className="text-bold" style={{ color: variables.warningColor, textTransform: 'uppercase' }}>{crop}</span>

    } catch(e) {
      return na;
    }
  }

  const renderDeclaredCoverCrop = (coverCrop) => {
    let na = <span className="text-bold" style={{color: variables.warningColor}}>N/A</span>;
    try {
      if(coverCrop === null || coverCrop === undefined) return na;

      if(coverCrop === 'NA' || coverCrop === 'N/A') return na;

      return <span className="text-bold" style={{ color: variables.warningColor, textTransform: 'uppercase' }}>{coverCrop ? 'YES' : 'NO'}</span>

    } catch(e) {
      return na;
    }
  }

  const renderDeclaredTillage = (tillage) => {
    let na = <span className="text-bold" style={{color: variables.warningColor}}>N/A</span>;

    try {
      if(tillage === null || tillage === undefined) return na;

      if(tillage === 'NA' || tillage === 'N/A') return na;

      if(typeof tillage === 'string') return <span className="text-bold" style={{color: variables.warningColor, textTransform: 'uppercase'}}>{tillage}</span>;

      return <IconButton sx={{padding: 0}} size="small" onClick={() => setTillageDeclaredModal(Object.keys(tillage).map(t => ({type: tillage[t], date: t})))}  color="secondary"><Info /></IconButton>
      return <Button onClick={() => setTillageDeclaredModal(Object.keys(tillage).map(t => ({type: tillage[t], date: t})))} size="small" sx={{p: '0 5px 0 0'}} variant="text" color="secondary" endIcon={<Info />}></Button>;

    } catch(e) {
      return na;
    }
  }

  const renderDetectedCrop = (obj) => {
    let na = <span className="text-bold" style={{color: variables.warningColor}}>N/A</span>;

    try {
      if(obj === null || obj === undefined || obj?.crop === 'NA' || obj?.crop === 'N/A') return na;

      if(obj === 'NA' || obj === 'N/A') return na;
      

      return <span className="text-bold" style={{ color: variables.greenColor, textTransform: 'uppercase' }}>{obj.crop} ({Math.round(obj.probability * 100)}%)</span>

    } catch(e) {
      return na;
    }
  }

  const renderDetectedCoverCrop = (obj) => {
    let na = <span className="text-bold" style={{color: variables.warningColor}}> - </span>;
    let notApplicable = <span className="text-bold" style={{color: variables.warningColor}}>Not applicable</span>;

    try {
      if(obj === null || obj === undefined) return na;

      if(obj === 'NA' || obj === 'N/A') return na;

      if(obj.cover_crop === null) return notApplicable
      

      return <span className="text-bold" style={{ color: obj.cover_crop ? variables.greenColor : variables.redColor, textTransform: 'uppercase' }}>{obj.cover_crop ?  'YES' : 'NO'}</span>

    } catch(e) {
      return na;
    }
  }

  const renderDetectedTillage = (tillage, residue) => {
    let na = <span className="text-bold" style={{color: variables.warningColor}}>N/A</span>;

    try {
      if(tillage === null || tillage === undefined) return na;

      if(tillage === 'NA' || tillage === 'N/A') return na;

      // if(tillage[0]?.date === null) {
      //   return <span className="text-bold" style={{ color: variables.greenColor, textTransform: 'uppercase' }}>{tillage[0]?.type}</span>
      // }
      return <IconButton sx={{padding: 0}} size="small"  onClick={() => setTillageModal(tillage.map(o => ({...o, residue: residue[o.date]})))}  color="secondary"><Info /></IconButton>

    } catch(e) {
      return na;
    }
  }

  const renderBurn = (obj, j) => {
    return null
    return (
      <tr key={j}>
        <td>BURN</td>
        <td className="text-bold" style={{ color: variables.warningColor }}> - </td>
        <td>
          {obj != null ? <COTooltip arrow title={<Stack spacing={2} direction="row" alignItems="center" justifyContent="space-evenly" flexWrap="wrap" >
            {obj.sort((a, b) => moment(a.date).diff(moment(b.date))).map((o, k) => <span key={k} className="text-bold" style={{ padding: '2px', marginBottom: '3px', color: variables.primary, border: '1px solid ' + variables.primary }}>{moment(o.date).format('DD/MMM/YYYY')}</span>)}
          </Stack>}><span className="text-bold" style={{ cursor: 'pointer', color: variables.dangerColor }}>DETECTED</span></COTooltip> : <span className="text-bold" style={{ color: variables.warningColor }}>N/A</span>}
        </td>
      </tr>
    )
  }

  const renderResidue = (val, j) => {
    return null;
    return (
      <tr key={j}>
        <td>RESIDUE</td>
        <td className="text-bold" style={{ color: variables.warningColor }}> - </td>

        <td>{val != null ?
          <span className="text-bold" style={{ color: variables.greenColor }}>{`${val}`}</span> :
          <span className="text-bold" style={{ color: variables.warningColor }}>N/A</span>}
        </td>
      </tr>
    )
  }

  const renderCoverCrop = (obj, j) => {
    return null
    return (
      <tr key={j}>
        <td>COVER CROP</td>
        <td>
          {obj != null ?
            <span className="text-bold" style={{ textTransform: 'upperCase', color: obj.declared_cover_crop ? variables.greenColor : variables.warningColor }}>{`${obj.declared_cover_crop != null ? (obj.declared_cover_crop ? `YES` : `NO`) : 'N/A'}`}</span> :
            <span className="text-bold" style={{ color: variables.warningColor }}>N/A</span>}
        </td>
        <td>{obj != null ? (obj.cover_crop ?
          <span className="text-bold" style={{ color: variables.greenColor }}>{`YES (${Math.round(obj.probability * 100)}%)`}</span> :
          <span className="text-bold" style={{ color: variables.dangerColor }}>{`NO (${Math.round(obj.probability * 100)}%)`}</span>) :
          <span className="text-bold" style={{ color: variables.warningColor }}>N/A</span>}

        </td>
      </tr>
    )
  }

  const renderTillage = (obj, j, residue, year) => {
    return null
    if(!obj) {
      return (
        <tr key={j}>
          <td>TILLAGE</td>
          <td>
            <span className="text-bold" style={{ color: variables.warningColor }}>N/A</span>
          </td>
          <td>
            <span className="text-bold" style={{ color: variables.warningColor }}>N/A</span>
          </td>
        </tr>
      )
    }

    if(obj.length === 0) {
      return (
        <tr key={j}>
          <td>TILLAGE</td>
          <td>
            <span className="text-bold" style={{ color: variables.warningColor }}>N/A</span>
          </td>
          <td>
            <span className="text-bold" style={{ color: variables.warningColor }}>N/A</span>
          </td>
        </tr>
      )
    }

    let declared = <span className="text-bold" style={{ color: variables.warningColor }}>N/A</span>;
    try {
      let dcl = obj.filter(o => o.hasOwnProperty('declared_tillage'))[0];
      console.log(dcl)
      if(dcl.declared_tillage !== 'NA') {
        console.log(Object.keys(dcl.declared_tillage))
        declared = <Button onClick={() => {setTillageDeclaredModal(Object.keys(dcl.declared_tillage).map((date => ({date, type: dcl.declared_tillage[date], year}))))}} variant="contained" fullWidth color="secondary" size="small">Declared</Button>

      }


    } catch(e) {
      declared = <span className="text-bold" style={{ color: variables.warningColor }}>N/A</span>;
    }

    return (
      <tr key={j}>
        <td>TILLAGE</td>
        <td>
          {declared}
        </td>
        <td style={{padding: '5px 0px'}}>
          <Button onClick={() => {setTillageModal(obj.map((o => ({...o, year, residue: residue[o.date]}))))}} variant="contained" fullWidth color="secondary" size="small">Detection</Button>
        </td>
      </tr>
    )
  }
  const fieldMap = {
    'cover_crop': renderCoverCrop,
    // 'crop': renderCrop,
    'burn': renderBurn,
    'residue': renderResidue,
    'tillage': renderTillage
  }





  const renderItems = () => {
    if (props.loading) {
      return <Stack sx={{ width: '100%', height: '100%' }} justifyContent="center" alignItems="center"><CircularProgress color="secondary" /></Stack>
    }

    if (Object.keys(props.data).length === 0) {
      return <Stack sx={{ width: '100%', height: '100%' }} justifyContent="center" alignItems="center">
        <p className="co-text-secondary text-bold">No verification info available for selected parcel</p>
      </Stack>
    }

    return Object.keys(props.data).map((year, i) => {
      let declared = props.data[year].declared;
      let detected = props.data[year].detected;
      return (
        <Box key={i} sx={{ padding: '5px 10px', boxSizing: 'border-box', height: '100%', border: '1px solid ' + variables.secondary }}>
          {/* <span className="co-text-secondary text-bold">{year}</span> */}
          <table className="verification-item-table">
            <tbody>
              <tr>
                <td className="co-text-secondary text-bold" style={{ fontSize: '16px' }}>{year}</td>
                <td style={{ textTransform: 'uppercase', color: variables.secondary, fontWeight: 'bold' }}>Declared</td>
                <td style={{ textTransform: 'uppercase', color: variables.secondary, fontWeight: 'bold' }}>Detected</td>
              </tr>
              <tr>
                <td>CROP</td>
                <td>{renderDeclaredCrop(declared?.crop)}</td>
                <td>{renderDetectedCrop(detected?.crop)}</td>
              </tr>
              <tr>
                <td>COVER CROP</td>
                <td>{renderDeclaredCoverCrop(declared?.cover_crop)}</td>
                <td>{renderDetectedCoverCrop(detected?.cover_crop)}</td>
              </tr>
              <tr>
                <td>TILLAGE</td>
                <td>{renderDeclaredTillage(declared?.tillage)}</td>
                <td>{renderDetectedTillage(detected?.tillage, detected?.residue)}</td>
              </tr>
              {/* {Object.keys(props.data[year]).map((item, j) => {
                if(item !== 'residue' && item !== 'tillage')
                return fieldMap[item](props.data[year][item], j);
              })}

              {renderTillage(props.data[year]['tillage'], -2, props.data[year]['residue'], year)} */}
            </tbody>
          </table>

        </Box>
      )
    });
  }

  const renderApplicabilityItems = () => {
    if (props.aLoading) {
      return <Stack sx={{ width: '100%', height: '100%' }} justifyContent="center" alignItems="center"><CircularProgress color="secondary" /></Stack>
    }
    console.log(props.applicabilityData);
    console.log(layerA);

    try {
      return Object.keys(props.applicabilityData[layerA]).map((year, i) => {
        return <Box key={i} sx={{ padding: '5px 10px', boxSizing: 'border-box', height: '100%', border: '1px solid ' + variables.secondary }}>
           <table className="verification-item-table">
            <tbody>
              <tr>
                <td className="co-text-secondary text-bold" style={{ fontSize: '16px' }}>{year}</td>
              </tr>
              <tr>
                <td className="text-bold co-text-secondary" style={{ fontSize: '14px', whiteSpace: 'break-spaces', wordBreak: 'break-word', width: 150 }}>{props.applicabilityData[layerA][year] ? props.applicabilityData[layerA][year] : ' - '}</td>
              </tr>
            </tbody>
          </table>
        </Box>
      })

    } catch (error) {
      console.log(error)
      return <Stack sx={{ width: '100%', height: '100%' }} justifyContent="center" alignItems="center">
        <p className="co-text-secondary text-bold">No applicability info available for selected location or selected layer</p>
      </Stack>
    }

    return null
  }

  const renderMonitoringChart = () => {
    if (props.mLoading) {
      return <Stack sx={{ width: '100%', height: '100%' }} justifyContent="center" alignItems="center"><CircularProgress color="secondary" /></Stack>
    }

    try {



      return <IndexChart onSetDatesState={props.onSetDatesState} data={props.pointData} />

    } catch (error) {
      return <Stack sx={{ width: '100%', height: '100%' }} justifyContent="center" alignItems="center">
        <p className="co-text-secondary text-bold">No monitoring info available for selected location or selected layer</p>
      </Stack>
    }
  }

  return (
    <Paper elevation={4} sx={{ position: 'absolute', bottom: '10px', right: '10px', width: 'calc(100% - 320px - 30px)', height: '190px' }} className="co-background-primary-blur fade-in verification-widget" >
      <Stack sx={{ width: '100%', height: '100%', position: 'relative' }}>
        <Fab onClick={props.onClose} size="small" children={<Close />} color="secondary" sx={{ color: "#fff", position: 'absolute', right: '-0px', top: '-45px' }} />
        <Stack sx={{ position: 'absolute', left: 0, top: '-38px' }} direction="row">
          <ButtonGroup orientation="horizontal">
            <Button className="verification-btn" onClick={() => props.onSetView('verification')} variant="contained" color={props.view === 'verification' ? 'secondary' : 'primary'}>VERIFICATION</Button>
            <Button className="verification-btn" onClick={() => props.onSetView('applicability')} variant="contained" color={props.view === 'applicability' ? 'secondary' : 'primary'}>APPLICABILITY (LOCATION)</Button>
            <Button className="verification-btn" onClick={() => props.onSetView('monitoring')} variant="contained" color={props.view === 'monitoring' ? 'secondary' : 'primary'}>Monitoring (LOCATION)</Button>
            <Fab size="small" className="verification-btn-mobile" onClick={() => props.onSetView('verification')} variant="contained" color={props.view === 'verification' ? 'secondary' : 'primary'}><Sensors sx={{mt: '3px'}} fontSize="small" /></Fab>
            <Fab size="small" className="verification-btn-mobile" onClick={() => props.onSetView('applicability')} variant="contained" color={props.view === 'applicability' ? 'secondary' : 'primary'}><TaskAlt sx={{mt: '3px'}} fontSize="small" /></Fab>
            <Fab size="small" className="verification-btn-mobile" onClick={() => props.onSetView('monitoring')} variant="contained" color={props.view === 'monitoring' ? 'secondary' : 'primary'}><PinDrop sx={{mt: '3px'}} fontSize="small" /></Fab>

          </ButtonGroup>

        </Stack>
        <Stack sx={{ padding: '10px', width: 'calc(100% - 20px)', height: '100%', overflow: 'auto' }} spacing={1} direction="row" alignItems="center">
          {props.view === 'verification' && renderItems()}
          {props.view === 'applicability' && (
            <>
              <p className="co-text-secondary text-bold">{applicabilityMap[layerA]}</p>
              {renderApplicabilityItems()}
            </>
          )}

          {props.view === 'monitoring' && renderMonitoringChart()}

        </Stack>
      </Stack>

      {tillageModal && <TillageModal title={`Tillage type detection results for ${tillageModal[0]?.year}`} year={tillageModal[0]?.year} open={Boolean(tillageModal)} data={tillageModal} onClose={() => setTillageModal(null)} />}
      {tillageDeclaredModal && <TillageModal isDeclared title={`Declared tillage type for ${tillageDeclaredModal[0]?.year}`} year={tillageDeclaredModal[0]?.year} open={Boolean(tillageDeclaredModal)} data={tillageDeclaredModal} onClose={() => setTillageDeclaredModal(null)} />}
    </Paper>
  )
}

export default VerificationWidget;