import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Parcel from "../../models/Parcel";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";
import { DataContext } from "../DataContext";
import SelectInput from "../ui/SelectInput";
import TextInput from "../ui/TextInput";
import WKT from 'ol/format/WKT';
import { AppContext } from "../../AppContext";
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from "chart.js";

import { Bar } from "react-chartjs-2";
import moment from "moment";
Chart.register(zoomPlugin, ChartDataLabels);

function TillageModal(props) {
  const { user } = useContext(AppContext);
  const { projectData, onShowSuccess, onUpdateProjects } = useContext(DataContext);

  const tillageTypes = {
    'intensive': 3,
    'reduced': 2,
    'no till': 1,
    '': 0,
  }

  const tillageTypesReverse = {
    0: '',
    1: 'No till',
    2: 'Reduced',
    3: 'Intensive'
  }

  const formatData = (data) => {
    let sorted = data.filter(obj => !obj.hasOwnProperty('declared_tillage')).sort((a,b) => moment(a.date).diff(moment(b.date)))
    try {

      if(!sorted[0]?.date) return 'no-till-detected';
      return {
        datasets: [{
          data: sorted.map(obj => ({
            x: obj.date || props.year,
            y: tillageTypes[obj.type],
            residue: obj.residue,
            probability: obj.probability ? Math.round(obj.probability * 100) + '%' : ""
          })),
          backgroundColor: sorted.map(obj => {
            if(obj.type === 'intensive') return variables.dangerColor;

            if(obj.type === 'reduced') return variables.materialWarning;

            if(obj.type === 'no till') return variables.primary;
          }),
          borderColor: sorted.map(obj => {
            if(obj.type === 'intensive') return 'darkred';

            if(obj.type === 'reduced') return 'darkorange';

            if(obj.type === 'no till') return 'darkgreen';
          }),
          barPercentage: 1,
          categoryPercentage: 1,
          borderWidth: 2,
          label: 'Tillage type',

        }]
      }
    } catch (e) {
      return null
    }


  }


  const formatOptions = () => {
    return {
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: 'nearest',
        axis: 'x'
      },
      animation: false,
      scales: {

        // x: {
        //   type: 'time',
        //   time: {
        //     unit: 'day',
        //     displayFormats: {
        //       day: 'YYYY-MM-DD'
        //     }
        //   },
        //   ticks: {
        //     maxRotation: 0,
        //     autoSkipPadding: 20,
        //     color: variables.primary
        //   }
        // },
        x: {
         
          ticks: {
            font: {
              weight: 'bold',
              size: '13px'
            },
            callback: (value, index, dataset) => {
              let sorted = props.data.filter(obj => !obj.hasOwnProperty('declared_tillage')).sort((a,b) => moment(a.date).diff(moment(b.date)))
              return sorted[value].date ? moment((sorted[value].date)).format('DD-MMM-YY') : props.year
            }
          }
        },

        y: {
          max: 3,
          min: 0,
          ticks: {
            color: [variables.dangerColor, variables.primary, variables.materialWarning],
            font: {
              weight: 'bold',
              size: '14px',

            },
            stepSize: 1,
          
            callback: (item) => {
              return tillageTypesReverse[item]
            }
          }
        }
      },
      plugins: {
        datalabels: {
          display: !props.isDeclared,
          color: '#fff',
          font: {
            weight: 'bold',
            size: '14px'
          },
          formatter: (value) => {
            
            return `P: ${value.probability}`;
          }
        },
        legend: {
          labels: {
            color: variables.primary,
            boxWidth: 0,
            boxHeight: 0,
            font: {
              size: '16px',
              weight: 'bold'
            }
          }
        },
        tooltip: {
          bodyFont: {
            weight: 'bold',
            // size: '14px',
          },
          footerFont: {
            weight: 'normal'
          },
          callbacks: {
            title: (context) => {
              return context[0].raw.x ? moment(context[0].raw.x).format('DD MMMM YYYY') : props.year
            },
            label: (context) => {

              return `${(tillageTypesReverse[context.parsed.y])}`
            },
            footer: (context) => {
              if(props.isDeclared) return;
              return 'Residue: ' + (context[0].raw.residue || 'N/A') + '\n' + `Probability: ${context[0].raw.probability}`;
            }

          }
        },
        // zoom: {
        //   onPan: () => { },
        //   onZoom: () => { },
        //   zoom: {

        //     wheel: {
        //       enabled: true
        //     },
        //     pinch: {
        //       enabled: true
        //     },
        //     mode: 'x'
        //   },
        //   pan: {

        //     enabled: true,
        //     threshold: 1,
        //     mode: 'x',
        //     modifierKey: 'shift'
        //   }
        // },
      }
    }
  }

  if (!props.data) return null;

  let formated = formatData(props.data);

  if (!formated) {
    return <Typography color="secondary" variant="body1"><strong>Information currently unavailable</strong></Typography>
  }

  if(formated === 'no-till-detected') {
    return (
      <Dialog maxWidth={'xl'} fullWidth PaperProps={{ className: 'co-background-white' }} open={props.open} onClose={props.onClose}>

      <DialogTitle color="primary">{props.title}</DialogTitle>
      <DialogContent>
        <div style={{ width: '100%', height: '200px', minWidth: '1100px', textAlign: 'center', paddingTop: '40px' }}>
          <Typography color="primary" variant="body1"><strong>No evidence of tillage activities detected</strong></Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button style={{ lineHeight: 'unset' }} color="error" onClick={props.onClose}>Close</Button>

      </DialogActions>
    </Dialog>
    )
  }

  return (
    <Dialog maxWidth={'xl'} fullWidth PaperProps={{ className: 'co-background-white' }} open={props.open} onClose={props.onClose}>

      <DialogTitle color="primary">{props.title}</DialogTitle>
      <DialogContent>
        <div style={{ width: '100%', height: '360px', minWidth: '1100px' }}>
          <Bar style={{ width: '100%', height: '100%' }} data={formated} options={formatOptions()} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button style={{ lineHeight: 'unset' }} color="error" onClick={props.onClose}>Close</Button>

      </DialogActions>
    </Dialog>
  )
}

export default TillageModal;